export default class BackEnd {
    getRESTApiUri()
    {
        let uri
        if (window.location.hostname == '192.168.1.50'){
            uri = window.location.protocol + "//" + window.location.hostname + ":3000/api/v1";
        }else{
            uri = window.location.protocol + "//" + window.location.hostname + "/api/v1";
        }
    return uri;
    }
}
