<template>
  <footer class="footer-container">
    <div class="footer-top">
      <div class="footer-logo">
        <a href="/">
          <img src="../assets/Logo.png" alt="Logo">
        </a>
      </div>
      <nav class="footer-navigation">
        <ul>
          <li><router-link to="/">Accueil</router-link></li>
          <li><router-link to="/products/implants">Implants</router-link></li>
          <li><router-link to="/products/matrice">Plateformes</router-link></li>
          <li><router-link to="/products/actionneurs-capteurs">Actionneurs et Capteurs</router-link></li>
          <li><router-link to="/logiciel/micecloud">Micecloud</router-link></li>
          <li><router-link to="/mission">Notre Mission</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </nav>
    </div>
    <div class="footer-bottom">
      <div class="footer-social">
        <!-- <a href="#" aria-label="Facebook">
            <v-icon style="font-size: 24px;">mdi-facebook</v-icon>
        </a>
        <a href="#" aria-label="Twitter">
            <v-icon style="font-size: 24px;">mdi-twitter</v-icon>
        </a>
        <a href="#" aria-label="Instagram">
            <v-icon style="font-size: 24px;">mdi-instagram</v-icon>
        </a> -->
        <a href="https://www.linkedin.com/company/micetracking/posts/?feedView=all" aria-label="LinkedIn">
          <v-icon style="font-size: 24px;">mdi-linkedin</v-icon>
        </a>
      </div>
      <div class="footer-info">
        <p>© 2024 MiceTracking. Tous droits réservés.</p>
        <p><router-link to="/terms">Mentions Légales</router-link> | <router-link to="/privacy">Politique de Confidentialité</router-link></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer-container {
  background-color: var(--grisclair);
  color: var(--noirdoux);
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-top: solid 1px var(--noirdoux);
}

.footer-top {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-logo img {
  width: 250px;
  height: auto;
}

.footer-navigation ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-navigation a {
  color: var(--noirdoux);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-navigation a:hover {
  color: var(--vertfonce);
}

.footer-bottom {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.footer-social a {
  margin: 0 10px;
  color: var(--noirdoux);
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: var(--vertfonce);
}

.footer-info p {
  margin: 5px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
</style>