import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg', // 'fa4', 'fa' 'mdi' || 'mdiSvg' || 'md' || 'faSvg'
	},
})