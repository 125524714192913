// Importez les fichiers de traduction pour chaque langue
import en from './en.json';
import fr from './fr.json';
import es from './es.json';

// Exportez un objet contenant les traductions par langue
export default {
  fr,
  en,
  es
};
